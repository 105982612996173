import Layout from '../../hocs/Layout';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { IoSettingsSharp } from "react-icons/io5";
import { IoIosStar, IoMdPricetags } from "react-icons/io";
import { TbTriangleSquareCircleFilled, TbWorld } from "react-icons/tb";
import { AutoSwiper } from '../../components/aditional/AutoSwiper';
import { RiBrainLine } from "react-icons/ri";
import { TiLightbulb, TiArrowMaximise } from "react-icons/ti";
import BoxEffect from '../../components/aditional/BoxEffect';
import { AnimatedText } from '../../components/aditional/AnimatedText';
import { LiaToolsSolid } from "react-icons/lia";
import { FaCheck } from "react-icons/fa";
import { CgStyle } from "react-icons/cg";
import '../../styles/web-design.css';


const WebDesignDetail = () => {

	const params = useParams();
	const packId = params.packId;

	useEffect(() => {
		window.scrollTo(0,0)
	}, []);

	const [x, setX] = useState(45);

  	const handleMouseMove = (e) => {
    	const rect = e.target.getBoundingClientRect();
    	const x = e.clientX - rect.left;
    	setX(x);
  	};

	return (

		<Layout>
			<div className='container-detail-title'>
				{(parseInt(packId) === 1) ? <AnimatedText text='Plan Básico' className='detail-webd-title' /> : <></>}
				{(parseInt(packId) === 2) ? <AnimatedText text='Plan Estándar' className='detail-webd-title' /> : <></>}
				{(parseInt(packId) === 3) ? <AnimatedText text='Plan Premium' className='detail-webd-title' /> : <></>}
			</div>
			<div className='container-our-work'>
				<div className='customer-support-header'>
					<LiaToolsSolid className='icon-header-detail work' />
					<h3>Nuestro trabajo</h3>
				</div>
				<div className='contain-our-work'>
					<div className='our-work-boxes'>
						<h3>Diseño web</h3>
						<div className='our-work-box'>
							<TiArrowMaximise className='our-work-icon' />
							<div>
								<h4>Flexible</h4>
								<p>El diseño web flexible se adapta a cualquier dispositivo, proporcionando una experiencia de usuario consistente en móviles, tabletas y computadoras de escritorio.</p>
							</div>
						</div>
						<div className='our-work-box'>
							<RiBrainLine className='our-work-icon' />
							<div>
								<h4>Inteligente</h4>
								<p>El diseño web flexible se adapta a cualquier dispositivo, proporcionando una experiencia de usuario consistente en móviles, tabletas y computadoras de escritorio.</p>
							</div>
						</div>
						<div className='our-work-box'>
							<TiLightbulb className='our-work-icon' />
							<div>
								<h4>Creativo</h4>
								<p>El diseño web flexible se adapta a cualquier dispositivo, proporcionando una experiencia de usuario consistente en móviles, tabletas y computadoras de escritorio.</p>
							</div>
						</div>
					</div>
					<div className='our-work-texts'>
						<h3>La integración efectiva de los enfoques más avanzados en diseño web</h3>
						<p>Para brindarte un servicio óptimo que maximice tu presencia en internet, captando la atención de tu audiencia y mejorando tu visibilidad en el entorno digital.</p>
						<Link 
							className='btn-discover' 
							to='https://wa.me/+543574511196?text=Hola,%20quiero%20obtener%20mi%20página%20web.' 
							style={{textDecoration: 'none'}} 
							onMouseMove={handleMouseMove}
			      			style={{ '--x': `${x}deg` }}>
							<i></i>
							<i></i>
							<span>Obten la tuya</span>
						</Link>
					</div>
				</div>
			</div>
			{(parseInt(packId) === 3) ? 
				<div className='container-predesign-ecommerce'>
					<div className='customer-support-header'>
						<IoIosStar className='icon-header-detail ecommerce' />
						<h3>E-commerce Personalizado</h3>
					</div>
					<div className='contain-predesign-ecommerce'>
						<div className='contain-predesign-image'>
							<img src={require(`../../images/ecommerce.jpg`)} alt='ecommerce' />
						</div>
						<div className='predesign-ecommerce-content'>
							<h3>¡Diseña el negocio de tus sueños!</h3>
							<p>Crea tu e-commerce personalizado desde cero, eligiendo tus propias imágenes, colores, animaciones y detalles estéticos. Lanza tu tienda online con opciones de pago seguras a través de Mercado Pago o PayPal.</p>
							<Link 
								className='btn-discover' 
								to='https://wa.me/+543574511196?text=Hola,%20quiero%20obtener%20mi%20página%20de%20e-commerce%20personalizada.' 
								style={{textDecoration: 'none'}} 
								onMouseMove={handleMouseMove}
			      				style={{ '--x': `${x}deg` }}>
								<i></i>
								<i></i>
								<span>Comienza ya</span>
							</Link>
						</div>
					</div>
				</div> : <></>}
			{(parseInt(packId) === 2) ? 
				<div className='container-predesign-ecommerce'>
					<div className='customer-support-header'>
						<IoIosStar className='icon-header-detail ecommerce' />
						<h3>E-commerce Prediseñado</h3>
					</div>
					<div className='contain-predesign-ecommerce'>
						<div className='contain-predesign-image'>
							<img src={require(`../../images/ecommerce.jpg`)} alt='ecommerce' />
						</div>
						<div className='predesign-ecommerce-content'>
							<h3>¡Sal a vender!</h3>
							<p>Tu página de ventas está lista. Elige tus imágenes, colores y detalles estéticos, y lanza tu e-commerce funcional 
							con opciones de pago seguras a través de Mercado Pago o PayPal.</p>
							<Link 
								className='btn-discover' 
								to='https://wa.me/+543574511196?text=Hola,%20quiero%20obtener%20mi%20página%20de%20e-commerce%20prediseñada.' 
								style={{textDecoration: 'none'}} 
								onMouseMove={handleMouseMove}
			      				style={{ '--x': `${x}deg` }}>
								<i></i>
								<i></i>
								<span>Comienza ya</span>
							</Link>
						</div>
					</div>
				</div>
			 : 
				<></>
			}
			{(parseInt(packId) === 3) ? 
				<div className='container-animation-details'>
					<div className='customer-support-header'>
						<CgStyle className='icon-header-detail animations' />
						<h3>Animaciones y diseño unico</h3>
					</div>
					<div className='contain-animation-details'>
						<div className='animation-details-title'>
							<h3>Lleva tu sitio web al siguiente nivel</h3>
							<p>Animaciones y estilo personalizados para una experiencia de usuario óptima</p>
						</div>
						<div className='container-grid-animation'>
							<div className='animation-content'>
								<div className='animation-face face-1'>
									<div className='content'>
										<h4>Animaciones que Cautivan</h4>
									</div>
								</div>
								<div className='animation-face face-2'>
									<div className='content'>
										<p>Haz de tu página web una obra maestra interactiva</p>
									</div>
								</div>
							</div>
							<div className='animation-content'>
								<div className='animation-face face-1'>
									<div className='content'>
										<h4>Diseño Personalizado a tu Medida</h4>
									</div>
								</div>
								<div className='animation-face face-2'>
									<div className='content'>
										<img src={require(`../../images/paginas.png`)} alt='paginas' className='content-img' />
									</div>
								</div>
							</div>
							<div className='animation-content'>
								<div className='animation-face face-1'>
									<div className='content'>
										<h4>Optimización para el Éxito</h4>
									</div>
								</div>
								<div className='animation-face face-2'>
									<div className='content'>
										<img src={require(`../../images/alto-rendimiento.png`)} alt='alto-rendimiento' className='content-opt-img' />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div> : <></>}
			<div className='container-all-types'>
				<div className='customer-support-header'>
					<TbWorld className='icon-header-detail all-types' />
					<h3>Tu página web</h3>
				</div>
				<div className='container-webd-boxes'>
					<BoxEffect />
				</div>
			</div>
			<div className='container-responsive-all'>
				<div className='customer-support-header'>
					<TbTriangleSquareCircleFilled className='icon-header-detail responsive' />
					<h3>Diseño responsivo</h3>
				</div>
				<div className='container-responsive-design'>
					<div className='contain-responsive-design'>			
						<h3>Diseño eficaz</h3>
						<p>Adaptado a todo tipo de pantallas</p>
					</div>
					<div className='responsive-design-image'>
						<AutoSwiper />
					</div>
				</div>
			</div>
			<div className='container-ecommerce-price'>
				<div className='customer-support-header'>
					<IoMdPricetags className='icon-header-detail price' />
					<h3>Precios del plan</h3>
				</div>
				<div className='contain-ecommerce-price'>
					<div className='ecommerce-price-title'>
						<h3>Te ofrecemos los mejores precios</h3>
					</div>
					<div className='ecommerce-price-text'>
						<div className='container-glassmorph'>
							<div className='glassmorph-box'>
								<span></span>
								<div className='glassmorph-content'>
									<h4 className='price-title'>Costo de mantenimiento mensual</h4>
									<p className='price-subtitle'>Incluye el mantenimiento del sitio web, costo mensual del dominio y del alojamiento web</p>
									{(parseInt(packId) === 1) ? <p className='price-text'>U$D 15.00</p> : <></>}
									{(parseInt(packId) === 2) ? <p className='price-text'>U$D 20.00</p> : <></>}
									{(parseInt(packId) === 3) ? <p className='price-text'>U$D 35.00</p> : <></>}
								</div>
							</div>
							<div className='glassmorph-box'>
								<span></span>
								<div className='glassmorph-content'>
									<h4 className='price-title'>Precio del sitio web</h4>
									<p className='price-subtitle'>Incluye la creación del sitio web, paginas del sitio, funcionalidades, animaciones y efectos</p>
									{(parseInt(packId) === 1) ? <p className='price-text'>U$D 80.00</p> : <></>}
									{(parseInt(packId) === 2) ? <p className='price-text'>U$D 120.00</p> : <></>}
									{(parseInt(packId) === 3) ? <p className='price-text'>U$D 180.00</p> : <></>}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='container-customer-support'>
				<div className='customer-support-header'>
					<IoSettingsSharp className='icon-header-detail' />
					<h3>Atención al cliente</h3>
				</div>
				<div className='contain-customer-support'>
					<div className='customer-support-content'>
						<p className='support-text'><FaCheck />Disponible las 24hs</p>
						<p className='support-text'><FaCheck />Atención personalizada</p>
						<p className='support-text'><FaCheck />Respuesta rápida y eficiente</p>
						<p className='support-text'><FaCheck />Seguimiento post-servicio</p>
						<p className='support-text'><FaCheck />Asesoramiento continuo</p>
						{(parseInt(packId) === 3) ? <p className='support-text'><FaCheck />Soporte técnico prioritario</p> : <></>}
					</div>
					<div className='customer-support-image'>
						<img src={require(`../../images/atencion-cliente.png`)} alt='atencion-cliente' />
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default WebDesignDetail;