import { Link } from 'react-router-dom';
import {
	FaPhoneAlt,
	FaMapMarkerAlt,
	FaEnvelope,
	FaFacebook,
	FaInstagram,
	FaPinterest,
	FaLinkedin,
	FaYoutube,
	FaRegEnvelope	 
} from "react-icons/fa";
import { FaXTwitter } from 'react-icons/fa6'

const Footer = () => {
	return (
		<div className='container-footer'>
			<div className='footer-logo'>
				<img src={require(`../../images/k-chingwhite.png`)} />
			</div>
			<div className='footer-social-media'>
				<Link to='https://www.facebook.com/people/K-Ching/100095470358464/?locale=es_LA' className='social-btn facebook' target='_blank' style={{textDecoration:'none'}}>
					<span id='span-1'></span>
					<span id='span-2'></span>
					<span id='span-3'></span>
					<span id='span-4'></span>
					<FaFacebook className='social-btn-icon' />
				</Link>
				<Link to='https://www.instagram.com/kching_ar/' className='social-btn instagram' target='_blank' style={{textDecoration:'none'}}>
					<span id='span-1'></span>
					<span id='span-2'></span>
					<span id='span-3'></span>
					<span id='span-4'></span>
					<FaInstagram className='social-btn-icon' />
				</Link>
				<Link to='https://ar.pinterest.com/LeoBrizio98/' className='social-btn pinterest' target='_blank' style={{textDecoration:'none'}}>
					<span id='span-1'></span>
					<span id='span-2'></span>
					<span id='span-3'></span>
					<span id='span-4'></span>
					<FaPinterest className='social-btn-icon' />
				</Link>
				<Link to='https://www.linkedin.com/in/leonel-brizio-940223245' className='social-btn linkedin' target='_blank' style={{textDecoration:'none'}}>
					<span id='span-1'></span>
					<span id='span-2'></span>
					<span id='span-3'></span>
					<span id='span-4'></span>
					<FaLinkedin className='social-btn-icon' />
				</Link>
				<Link to='https://www.youtube.com/@K-ching' className='social-btn youtube' target='_blank' style={{textDecoration:'none'}}>
					<span id='span-1'></span>
					<span id='span-2'></span>
					<span id='span-3'></span>
					<span id='span-4'></span>
					<FaYoutube className='social-btn-icon' />
				</Link>
			</div>
			<div className='container-privacy'>
				<Link to='/privacy-policies' style={{textDecoration:'none'}} className='privacy-links'>Política de Privacidad</Link>
				<Link to='/purchasing-policies' style={{textDecoration:'none'}} className='privacy-links'>Política de Compras</Link>
				<Link to='/cookies-policies' style={{textDecoration:'none'}} className='privacy-links'>Política de Cookies</Link>
			</div>
			<div className='footer-contact'>
				<h4 className='contact-title-footer'>CONTÁCTANOS</h4>
				<p className='contact-text'><FaRegEnvelope />contactokching@gmail.com</p>
				<p className='contact-text'><FaPhoneAlt />+54 3574-511196</p>
			</div>
			<div className='footer-copy'>
				<span className='container-rights'>K-Ching {new Date().getFullYear()} &copy; Todos los Derechos Reservados.</span>
			</div>
		</div>
	);
};

export default Footer;