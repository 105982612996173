import Layout from '../../hocs/Layout';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FaRegRegistered } from "react-icons/fa";
import { IoIosColorPalette } from "react-icons/io";
import { MdOutlineDesignServices } from "react-icons/md";
import { FaHandPointRight } from "react-icons/fa";
import '../../styles/services.css';


const DigitalMarketing = () => {

	useEffect(() => {
		window.scrollTo(0,0)
	}, []);

	const [x, setX] = useState(45);
	const [visibleDescriptionIndex, setVisibleDescriptionIndex] = useState(null);

  	const handleMouseMove = (e) => {
    	const rect = e.target.getBoundingClientRect();
    	const x = e.clientX - rect.left;
    	setX(x);
  	};

	const handleTitleClick = (index) => {
		setVisibleDescriptionIndex(visibleDescriptionIndex === index ? null : index);
	};

	const marketingData = [
  		{	
  			id: 1,
    		title: "Diseño de Marca Básico",
    		price: "AR$ 25.000",
    		description: "Diseño de marca, manual normativo básico (no incluye creación de logo)",
  		},
  		{	
  			id: 2,
    		title: "Diseño de Marca Avanzado",
    		price: "AR$ 50.000",
    		description: "Diseño de marca, manual normativo, creación de logotipo, y eslogan",
  		},
  		{	
  			id: 3,
    		title: "Diseño de Logotipo Básico",
    		price: "AR$ 10.000",
    		description: "Diseño y re-creación de logotipo",
  		},
  		{	
  			id: 4,
    		title: "Diseño de Logotipo Avanzado",
    		price: "AR$ 30.000",
    		description: "Diseño y re-creación de logotipo, isotipo, imagotipo, isologo, elección de paleta de colores",
  		},
  		{	
  			id: 5,
    		title: "Carrusel de Imágenes en Instagram o Facebook Básico",
    		price: "AR$ 5.000",
    		description: "Carrusel de 10 fotos con retoques y fitros",
  		},
  		{	
  			id: 6,
    		title: "Carrusel de Imágenes en Instagram o Facebook Personalizado",
    		price: "AR$ 8.000",
    		description: "Carrusel de 10 imágenes personalizadas, publicitarias, editadas, diseñadas a medida con retoques y fitros",
  		},
  		{	
  			id: 7,
    		title: "Reel de Instagram o Facebook Básico",
    		price: "AR$ 4.000",
    		description: "Reel de Instagram o Facebook de hasta 30s de duración",
  		},
  		{	
  			id: 8,
    		title: "Reel de Instagram o Facebook Avanzado",
    		price: "AR$ 5.500",
    		description: "Reel de Instagram o Facebook mayor a 30s hasta 60s de duración",
  		},
  		{	
  			id: 9,
    		title: "Video Publicitario",
    		price: "AR$ 5.000",
    		description: "Video publicitario de hasta 1 minuto de duración",
  		},
  		{	
  			id: 10,
    		title: "Video Publicitario por Minuto Extra",
    		price: "AR$ 3.I00",
    		description: "Costo por cada minuto extra del video publicitario",
  		},
  		{	
  			id: 11,
    		title: "Creación de Catálogo de Redes Sociales Básico",
    		price: "AR$ 10.000",
    		description: "Control de facebook e instagram, 2 historias y 1 publicación semanal, 1 reel al mes",
  		},
  		{	
  			id: 12,
    		title: "Creación de Catálogo de Redes Sociales Estándar",
    		price: "AR$ 15.000",
    		description: "Control de facebook e instagram, 2 historias y 1 publicación semanal, 1 reel al mes",
  		},
  		{	
  			id: 13,
    		title: "Creación de Catálogo de Redes Sociales Premium",
    		price: "AR$ 20.000",
    		description: "Control de facebook e instagram, 2 historias y 1 publicación semanal, 1 reel al mes",
  		},
  		{	
  			id: 14,
    		title: "Foto de Producto",
    		price: "AR$ 3.000",
    		description: "Fotografía de tu producto profesional, para crear tus catálogos, subir a redes o sitio web, precio por unidad",
  		},
  		{	
  			id: 15,
    		title: "Manejo Parcial de tus Redes Sociales",
    		price: "AR$ 50.000",
    		description: "Control de redes, 2 historias y 1 publicación semanal, 1 reel al mes",
  		},
  		{	
  			id: 16,
    		title: "Manejo Total de tus Redes Sociales",
    		price: "AR$ 75.000",
    		description: "Control de redes, 3 historias y 3 publicaciones semanales, 1 reel al mes, respuesta automática",
  		},
	];

	return (

		<Layout>
			<div className='container-brandind-marketing'>
				<h3>Crea tu logo, definí tus colores y destaca tu marca</h3>
				<div className='container-marketing-boxes'>
					<div className='branding-marketing-box'>
						<MdOutlineDesignServices className='icon-brand-marketing' />
						<h4>El Logo</h4>
						<p>El logo es la representación visual de tu marca y el primer punto de contacto con tus clientes. Un buen logo debe ser único, memorable y reflejar la esencia de tu negocio.</p>
						<Link 
							className='btn-box-marketing' 
							to='https://wa.me/+543574511196?text=Hola,%20quiero%20crear%20mi%20logo.' 
							style={{textDecoration: 'none'}}>
							¡Créalo!
						</Link>
					</div>
					<div className='branding-marketing-box'>
						<IoIosColorPalette className='icon-brand-marketing' />
						<h4>Los Colores</h4>
						<p>El logo es la representación visual de tu marca y el primer punto de contacto con tus clientes. Un buen logo debe ser único, memorable y reflejar la esencia de tu negocio.</p>
						<Link 
							className='btn-box-marketing' 
							to='https://wa.me/+543574511196?text=Hola,%20quiero%20crear%20mi%20logo.' 
							style={{textDecoration: 'none'}}>
							¡Elígelos!
						</Link>
					</div>
					<div className='branding-marketing-box'>
						<FaRegRegistered className='icon-brand-marketing' />
						<h4>La Marca</h4>
						<p>El logo es la representación visual de tu marca y el primer punto de contacto con tus clientes. Un buen logo debe ser único, memorable y reflejar la esencia de tu negocio.</p>
						<Link 
							className='btn-box-marketing' 
							to='https://wa.me/+543574511196?text=Hola,%20quiero%20crear%20mi%20logo.' 
							style={{textDecoration: 'none'}}>
							¡Posiciónate!
						</Link>
					</div>
				</div>
			</div>
			<div className='container-advertising-design'>
				<div className='container-advertising-description'>
					<h3>Diseñamos tu publicidad a medida</h3>
				</div>
				<div className='contain-advertising-design'>
					<div className='grid-advertising-1'>
						<h4>Destaca en redes sociales</h4>
					</div>
					<div className='grid-advertising-image'>
						<img src={require(`../../images/diseño-publicitario.jpg`)} />
					</div>
					<div className='grid-advertising-image'>
						<img src={require(`../../images/diseño-publicitario1.jpg`)} />
					</div>
					<div className='grid-advertising-image'>
						<img src={require(`../../images/diseño-publicitario2.jpg`)} />
					</div>
					<div className='grid-advertising-image'>
						<img src={require(`../../images/diseño-publicitario3.jpg`)} />
					</div>
					<div className='grid-advertising-2'>
						<h4>Genera confianza con tu público</h4>
					</div>
				</div>
			</div>
			<div className='container-marketing-estrategy'>
				<div className='contain-marketing-estrategy'>
					<img src={require(`../../images/monitor.png`)} />
				</div>
				<h3>Una estrategia de marketing para destacar</h3>
			</div>
			<div className='container-marketing-services'>
				<h3>Nuestros servicios</h3>
				<div>
					<div className='contain-marketing-services'>
						<div className='container-grid-services'>
							<h4>Servicio</h4>
							<h4 className='services-description'>Descripción</h4>
							<h4>Precio</h4>
							<h4>Link</h4>
						</div>
	      				{marketingData.map((marketing, index) => (
	        				<div key={index} className='container-grid-services'>
	        					<h4 className='services-title' onClick={() => handleTitleClick(index)}>{marketing.title}</h4>
	        					<p className={`services-description ${visibleDescriptionIndex === index ? 'show-description' : ''}`}>{marketing.description}</p>
	        					<p>{marketing.price}</p>
	        					<Link to={`https://wa.me/+543574511196?text=Hola,%20me%20interesa%20su%20${marketing.title}.`}  className='icon-to-prices' style={{textDecoration: 'none'}}><FaHandPointRight /></Link>
	        				</div>
	      				))}
	      			</div>
				</div>
			</div>
		</Layout>
	);
};

export default DigitalMarketing;