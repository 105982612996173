import Layout from '../../hocs/Layout';
import { Link } from 'react-router-dom';
import { WhatsAppButton } from '../../components/aditional/WhatsApp';
import '../../styles/static-pages.css';
import React, { useState, useEffect } from 'react';
import { AnimatedText } from '../../components/aditional/AnimatedText';
import { PackCard } from '../../components/cards/PackCard';
import { Slider } from '../../components/aditional/Slider';


const Home = () => {

	useEffect(() => {
		window.scrollTo(0,0)
	}, []);

	const [x, setX] = useState(45);

  	const handleMouseMove = (e) => {
    	const rect = e.target.getBoundingClientRect();
    	const x = e.clientX - rect.left;
    	setX(x);
  	};

	const packsData = [
  		{	
  			id: 1,
    		title: "Plan Básico",
    		subtitle: "Subtítulo del pack 1",
    		price: 15,
    		paragraphs: [
      			{ titleParagraph: "Página Web Institucional", paragraph: "Diseño y desarrollo de un sitio web estático que presente información básica sobre la empresa o negocio." },
      			{ titleParagraph: "Blog Simple", paragraph: "Integración de un blog básico para publicar contenido relevante y mejorar la interacción con los visitantes del sitio." },
      			{ titleParagraph: "Landing Page", paragraph: "Creación de una landing page efectiva para promocionar productos, eventos o servicios específicos." },
      			{ titleParagraph: "Curriculum Vitae Online", paragraph: "Desarrollo de un currículum vitae digital para destacar las habilidades y experiencia del cliente." },
      			{ titleParagraph: "Portal Web", paragraph: "Creacion de un sitio web que contenga datos de servicio, ubicación, redes sociales entre otras." },
      			{ titleParagraph: "Diseño Responsive:", paragraph: "Adaptación del diseño para garantizar una experiencia óptima en dispositivos móviles." },
      			{ titleParagraph: "Soporte Técnico Básico", paragraph: "Asistencia técnica limitada para resolver problemas relacionados con la página web." }
    		]
  		},
  		{	
  			id: 2,
  			className: "recomended",
    		title: "Plan Estándar",
    		subtitle: "Subtítulo del pack 2",
    		price: 20,
    		paragraphs: [
      			{ titleParagraph: "Página Web Institucional Avanzada", paragraph: "Diseño y desarrollo de un sitio web más completo con contenido adicional y mayor funcionalidad." },
      			{ titleParagraph: "Funcionalidades Avanzadas", paragraph: "Incorporación de características como carrusel de imágenes, galería de fotos, integración de redes sociales, etc." },
      			{ titleParagraph: "Ecommerce Prediseñado", paragraph: "Integración de una tienda en línea básica con un diseño y flujo de usuario predefinidos. Incluye funcionalidades esenciales como catálogo de productos, carrito de compras y proceso de pago." },
      			{ titleParagraph: "Blog Integrado", paragraph: "Agregado de un blog para publicar contenido relevante y mejorar el SEO." },
      			{ titleParagraph: "SEO Básico", paragraph: "Optimización básica para motores de búsqueda." },
      			{ titleParagraph: "Diseño Responsive", paragraph: "Adaptación del diseño para garantizar una experiencia óptima en dispositivos móviles." },
      			{ titleParagraph: "Soporte Técnico Estándar", paragraph: "Asistencia técnica extendida para resolver problemas y realizar actualizaciones regulares." }
    		]
  		},
  		{	
  			id: 3,
    		title: "Plan Premium",
    		subtitle: "Subtítulo del pack 2",
    		price: 35,
    		paragraphs: [
      			{ titleParagraph: "Ecommerce Personalizado", paragraph: "Creación de una tienda en línea completa con capacidad para listar y vender productos." },
      			{ titleParagraph: "Diseño Premium y Personalizado", paragraph: "Desarrollo de un diseño altamente personalizado con elementos avanzados de diseño y animaciones." },
      			{ titleParagraph: "Optimización SEO Avanzada", paragraph: "Estrategias avanzadas de SEO para mejorar la visibilidad en los motores de búsqueda y aumentar el tráfico orgánico." },
      			{ titleParagraph: "Integración de Pago", paragraph: "Configuración de pasarelas de pago seguras para procesar transacciones en línea." },
      			{ titleParagraph: "Funcionalidades Avanzadas", paragraph: "Integración de características como sistema de gestión de clientes, chat en vivo, etc." },
      			{ titleParagraph: "Diseño Responsive", paragraph: "Adaptación del diseño para garantizar una experiencia óptima en dispositivos móviles." },
      			{ titleParagraph: "Soporte Técnico Prioritario", paragraph: "Asistencia técnica prioritaria con tiempos de respuesta más rápidos y mayor disponibilidad." }

    		]
  		}
	];
	
	return (
		<Layout>
			<div className='container-banner-home'>
				<div className='contain-banner-home'>
					<div className='banner-home-title'>
						<h2>Tu Portal al</h2>
						<h2 className='home-title'>Éxito Digital</h2>
					</div>
					<p>Confía en nosotros para transformar tu presencia online y sobresalir en el entorno digital</p>
					<Link to='/services' className='social-btn home see-more-home' target='_blank' style={{textDecoration:'none'}}>
						<span id='span-1'></span>
						<span id='span-2'></span>
						<span id='span-3'></span>
						<span id='span-4'></span>
						VER MÁS
					</Link>
				</div>
				<div className='container-home-image'>
					<img src={require(`../../images/home-laptop.png`)} />
				</div>
			</div>
			<div className='container-caracteristics'>
				<h3>Todo lo que necesitas para tu emprendimiento</h3>
				<div className='container-items'>
					<div className='caracteristics-items'>
						<div className='caracteristics-item-img'>
							<img src={require(`../../images/web-design.png`)} alt='web-design' />
						</div>
						<div className='caracteristics-see-more'>
							<Link to='/services/web-design' className='see-more-button-car' style={{textDecoration: 'none'}}>Ver mas</Link>
						</div>
						<div className='caracteristics-item-text'>
							<p>Desarrollo Web</p>
						</div>
					</div>
					<div className='caracteristics-items'>
						<div className='caracteristics-item-img'>
							<img src={require(`../../images/software.png`)} alt='software' />
						</div>
						<div className='caracteristics-see-more'>
							<Link to='/services' className='see-more-button-car' style={{textDecoration: 'none'}}>Ver mas</Link>
						</div>
						<div className='caracteristics-item-text'>
							<p>Software de Ventas</p>
						</div>
					</div>
					<div className='caracteristics-items'>
						<div className='caracteristics-item-img'>
							<img src={require(`../../images/digital-marketing.png`)} alt='digital-marketing' />
						</div>
						<div className='caracteristics-see-more'>
							<Link to='/services/digital-marketing' className='see-more-button-car' style={{textDecoration: 'none'}}>Ver mas</Link>
						</div>
						<div className='caracteristics-item-text'>
							<p>Marketing Digital</p>
						</div>
					</div>
					<div className='caracteristics-items'>
						<div className='caracteristics-item-img'>
							<img src={require(`../../images/adds-design.png`)} alt='adds-design' />
						</div>
						<div className='caracteristics-see-more'>
							<Link to='/services/digital-marketing' className='see-more-button-car' style={{textDecoration: 'none'}}>Ver mas</Link>
						</div>
						<div className='caracteristics-item-text'>
							<p>Diseño Publicitario</p>
						</div>
					</div>
				</div>			
			</div>
    		<div className='container-web-design'>
				<div className='container'>
					<h3 className='packs-title'>Nuestros planes de desarrollo web</h3>
					<div className='container-packs'>
	      				{packsData.map((pack, index) => (
	        				<PackCard  key={index} {...pack} />
	      				))}
	      			</div>
	    		</div>
    		</div>
    		<div className='container-products-about'>
				<div className='container'>
					<div className='products-about'>
						<div className='contain-advantages'>
							<h3>Ventajas de una Tienda Online</h3>
							<p className='advantage-title'>Si tenés un negocio físico, te vas a dar cuenta la cantidad de ventajas que tiene tener una tienda online.</p>
							<div className='advantage-texts'>
								<p>No necesitas vendedores</p>
								<p>No tenés que alquilar un local</p>
								<p>No pagar servicios de agua, gas, luz, limpieza, etc.</p>
								<p>No se toma vacaciones (está activa las 24 hs de los 365 días del año)</p>
								<p>Puede atender a varias personas a la vez</p>
								<p>Vender a cualquier parte del país</p>
							</div>
						</div>
						<div className='contain-standar-pack'>
							<div className='content-pack'>
								<h4>Te recomendamos nuestro plan estándar</h4>
								<p>Ecommerce prediseñado</p>
								<p>Incluye tu propio logo, elige tus colores y algunos detalles de tu tienda prediseñada</p>
								<Link 
									className='btn-discover' 
									to='/services/ecommerce' 
									style={{textDecoration: 'none'}} 
									onMouseMove={handleMouseMove}
					      			style={{ '--x': `${x}deg` }}>
									<i></i>
									<i></i>
									<span>Obtenlo ahora</span>
								</Link>
							</div>
							<img src={require(`../../images/ecommerce.jpg`)} alt='ecommerce' />
						</div>
					</div>
				</div>
			</div>
			<div className='container-trusted-background'>
				<div className='container-trusted'>
					<h3 className='trusted-title'>Ya confiaron en nosotros</h3>
					<div className='contain-trusted'>
						<Link to='https://registrobienesmuebles.com.ar' className='container-links-trusted' target='_blank' style={{textDecoration: 'none'}}><img src={require(`../../images/logorbm.png`)} alt='logo-rbm' /></Link>
						<Link to='https://brendaledesma.blog' className='container-links-trusted' target='_blank' style={{textDecoration: 'none'}}><p className='brenda-ledesma'>Brenda Ledesma</p></Link>
					</div>
				</div>
			</div>
			<div className='container-slider-main'>
				<div className='contain-slider-main'>
					<h3>Diseña tu publicidad con nosotros</h3>
					<Link 
						className='btn-about' 
						to='/services/digital-marketing' 
						style={{textDecoration: 'none'}} 
						onMouseMove={handleMouseMove}
	      				style={{ '--x': `${x}deg` }}>
						<i></i>
						<i></i>
						<span>Comenzar</span>
					</Link>
				</div>
    			<Slider />
    		</div>
    		<div className='container-about-main'>
				<div className='content-about-main'>
					<div className='about-main-image'>
						<img src={require(`../../images/laptop.png`)} alt='laptop-about' />
					</div>
					<div className='main-about-items'>
						<h3>¿Excel? Si, por favor</h3>
						<p className='about-main-text'>¡Descubre cómo hemos transformado nuestra pasión por las plantillas de Excel en una aventura digital! Sumérgete en nuestra sección "Sobre mí" y conoce la historia detrás de nuestro viaje, desde simples hojas de cálculo hasta la creación de sitios web cautivadores, software a medida y estrategias de marketing digital que impulsan a los pequeños emprendedores hacia el éxito. ¡Únete y sé testigo de nuestra evolución!</p>
						<Link 
							className='btn-about' 
							to='/about' 
							style={{textDecoration: 'none'}} 
							onMouseMove={handleMouseMove}
	      					style={{ '--x': `${x}deg` }}>
							<i></i>
							<i></i>
							<span>Sobre Mí</span>
						</Link>
					</div>
				</div>
			</div>
			<WhatsAppButton />
		</Layout>
	);
};

export default Home