import { useState } from 'react';
import '../../styles/cards.css'
import { Link } from 'react-router-dom';
import { FaCheck } from "react-icons/fa";


export const PackCard = ({ id, title, subtitle, price, paragraphs, link, className }) => {

	const [x, setX] = useState(45);

  	const handleMouseMove = (e) => {
    	const rect = e.target.getBoundingClientRect();
    	const x = e.clientX - rect.left;
    	setX(x);
  	};

	return (
		<div className='container-glass-pack'>
			<div className={`container-pack ${(id == 2) ? 'recommended' : ''}`}>
				{(id == 2) ? <p className='recommended-text'>Recomendado</p> : <></> }
				<div className='pack-title'>
					<h3>{title}</h3>
					<p>{subtitle}</p>
				</div>
				<div className='pack-price'>
					<p>U$D</p>
					<p className='price-number'>{price}</p>
					<p>/mes</p>
				</div>
				<div className='pack-add-cart'>
					<Link 
						className='btn-about' 
						to={`https://wa.me/+543574511196?text=Hola,%20me%20interesa%20comprar%20su%20${title}.`} 
						style={{textDecoration: 'none'}} 
						onMouseMove={handleMouseMove}
      					style={{ '--x': `${x}deg` }}>
      					<i></i>
      					<i></i>
      					<span>Lo quiero</span>
      				</Link>
				</div>
				<div className='pack-paragraph'>
					{paragraphs.map((paragraph, index) => (
						<div key={index} className='index-paragraph'>
							<p className='check-list-text'><FaCheck className='check-list' />{paragraph.titleParagraph}</p>
							<span>{paragraph.paragraph}</span>
						</div>
					))}
				</div>
				<div className='pack-link'>
					<Link to={`/services/web-design/${id}`} className='pack-links' style={{textDecoration:'none'}}>Conocer mas sobre el plan</Link>
				</div>
			</div>
		</div>
	);
};