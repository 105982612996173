import { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { Link } from 'react-router-dom';
import '../../styles/components.css';
import {
    FaFacebook,
    FaInstagram,
    FaPinterest,
    FaYoutube,
    FaLinkedin,
} from "react-icons/fa";


export const ContactForm = () => {

    const form = useRef();
    const [sendedEmail, setSendedEmail] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_mwgltv9', 'template_sco1pyb', form.current, {
                publicKey: '22tKECBM3o4X0FoPS',
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                    setSendedEmail(true);
                    form.current.reset();
                },
                (error) => {
                    console.log('FAILED...', error.text);
                },
            );
    };

    return (
        <div className='container container-contact-form'>
            <div className='container-info-contact'>
                <div className='contain-contact-paragraph'>
                    <h3>Comunícate conmigo</h3>
                    <p>Si te gustaria conocer más, acerca de los servicios que ofrecemos, 
                    comunícate conmigo a través de los diferentes medios</p>
                </div>
                <div className='contain-info-contact'>
                    <p>Número de Whatsapp</p>
                    <Link to='https://wa.me/+543574511196?text=Hola,%20me%20interesan%20sus%20servicios%20web.' className='info-contact-link' style={{textDecoration: 'none'}}>+54 9 3574-511196</Link>
                </div>
                <div className='contain-info-contact'>
                    <p>Email</p>
                    <Link to='mailto:contactokching@gmail.com' className='info-contact-link' style={{textDecoration: 'none'}}>contactokching@gmail.com</Link>
                </div>
                <div className='container-contact-social'>
                    <Link to='https://www.facebook.com/people/K-Ching/100095470358464/?locale=es_LA' target='_blank' className='social-btn facebook' style={{textDecoration: 'none'}}>
                        <span id='span-1'></span>
                        <span id='span-2'></span>
                        <span id='span-3'></span>
                        <span id='span-4'></span>
                        <FaFacebook />
                    </Link>
                    <Link to='https://www.instagram.com/kching_ar/' target='_blank' className='social-btn instagram' style={{textDecoration: 'none'}}>
                        <span id='span-1'></span>
                        <span id='span-2'></span>
                        <span id='span-3'></span>
                        <span id='span-4'></span>
                        <FaInstagram />
                    </Link>
                    <Link to='https://ar.pinterest.com/LeoBrizio98/' target='_blank' className='social-btn pinterest' style={{textDecoration: 'none'}}>
                        <span id='span-1'></span>
                        <span id='span-2'></span>
                        <span id='span-3'></span>
                        <span id='span-4'></span>
                        <FaPinterest />
                    </Link>
                    <Link to='https://www.linkedin.com/in/leonel-brizio-940223245' target='_blank' className='social-btn linkedin' style={{textDecoration: 'none'}}>
                        <span id='span-1'></span>
                        <span id='span-2'></span>
                        <span id='span-3'></span>
                        <span id='span-4'></span>
                        <FaLinkedin />
                    </Link>
                    <Link to='https://www.youtube.com/@K-ching' target='_blank' className='social-btn youtube' style={{textDecoration: 'none'}}>
                        <span id='span-1'></span>
                        <span id='span-2'></span>
                        <span id='span-3'></span>
                        <span id='span-4'></span>
                        <FaYoutube />
                    </Link>
                </div>
            </div>
            <form className='contact-form' ref={form} onSubmit={sendEmail}>
                <div className='contain-contact-form'>
                    <div className='container-contact-divider'>
                        <div className='input-contact'>
                            <input
                                type='text'
                                name='user_name'
                                className='input-contact-form'
                                required
                            />
                            <span>Nombre</span>
                            <i></i>
                        </div>
                        <div className='input-contact'>
                            <input
                                type='email'
                                name='user_email'
                                className='input-contact-form'
                                required
                            />
                            <span>Email</span>
                            <i></i>
                        </div>
                    </div>
                    <div className='container-contact-divider'>
                        <div className='input-contact'>
                            <input 
                                type='text' 
                                name='subject' 
                                className='input-contact-form' 
                                required 
                            />
                            <span>Asunto</span>
                            <i></i>
                        </div>
                        <div className='input-contact'>
                            <input
                                    name='phone'
                                    type='text'
                                    className='input-form'
                                    required
                                />
                            <span>Teléfono</span>
                            <i></i>
                        </div>
                    </div>
                    <div className='input-contact-msg'>
                        <textarea name='message' className='input-contact-form' required></textarea>
                        <span>Mensaje</span>
                        <i></i>
                    </div>
                    <p className='required-fields'>Todos los campos son obligatorios</p>
                    <div className='container-submit-button'>
                        {sendedEmail ? (
                            <p className='submited-contact-button'>Enviado</p>
                        ) : (
                            <input type='submit' value='Enviar' className='submit-contact-button' />
                        )}
                    </div>
                </div>
            </form>
        </div>
    )
};