import Layout from '../../hocs/Layout';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { IoShieldCheckmarkOutline, IoLogoJavascript } from "react-icons/io5";
import { LuExpand } from "react-icons/lu";
import { FaRegEye } from "react-icons/fa6";
import { FaReact, FaPython, FaHtml5, FaCss3Alt } from "react-icons/fa";
import { BiLogoDjango } from "react-icons/bi";
import { ThreeDots } from 'react-loader-spinner';
import { FAQ } from '../../components/aditional/FAQ';
import '../../styles/services.css';


const Services = () => {

	useEffect(() => {
		window.scrollTo(0,0)
	}, []);

	const [x, setX] = useState(45);

  	const handleMouseMove = (e) => {
    	const rect = e.target.getBoundingClientRect();
    	const x = e.clientX - rect.left;
    	setX(x);
  	};

	return (

		<Layout>
			<div className='container-web-design-service'>
				<div className='container-description-webd'>
					<h3 className='description-webd-title'>Diseño web</h3>
					<p>En resumen, una página web es esencial en el entorno digital actual porque proporciona visibilidad, credibilidad, y una 
						plataforma para estrategias de marketing eficaces, mejora la interacción con los clientes, aumenta la competitividad, optimiza 
						operaciones y permite la expansión global.
					</p>
					<Link 
						className='btn-about' 
						to='/services/web-design' 
						style={{textDecoration: 'none'}} 
						onMouseMove={handleMouseMove}
      					style={{ '--x': `${x}deg` }}>
						<i></i>
						<i></i>
						<span>Ver más</span>
					</Link>
				</div>
				<div className='container-boxes'>
					<div className='contain-box box-1'>
						<IoShieldCheckmarkOutline className='box-icon' />
						<h4 className='box-title'>Credibilidad</h4>
						<p className='box-text'>Tener una página web bien diseñada y actualizada refuerza la credibilidad y profesionalismo de una marca o individuo. Los consumidores tienden a confiar más en negocios que tienen una presencia en línea sólida y profesional.</p>
					</div>
					<div className='contain-box box-2'>
						<LuExpand className='box-icon' />
						<h4 className='box-title'>Expansión</h4>
						<p className='box-text'>Una página web ofrece la oportunidad de expandir tu alcance más allá de las limitaciones geográficas. Puedes llegar a una audiencia global, lo que es especialmente beneficioso para negocios que venden productos o servicios en línea.</p>
					</div>
					<div className='contain-box box-3'>
						<FaRegEye className='box-icon' />
						<h4 className='box-title'>Visibilidad</h4>
						<p className='box-text'>En un mundo cada vez más digital, una página web actúa como la tarjeta de presentación en línea de cualquier negocio, profesional o entidad. Estar presente en internet es crucial para que los clientes potenciales puedan encontrar y acceder a tus productos o servicios.</p>
					</div>
					<div className='contain-box box-4'>
						<LuExpand className='box-icon' />
						<h4 className='box-title'>Competitividad</h4>
						<p className='box-text'>En la economía digital, la competencia es feroz. Una página web bien diseñada puede diferenciarte de tus competidores. Permite mostrar sus productos de manera detallada y atractiva, destacando sus beneficios y captar la atención de manera efectiva.</p>
					</div>
				</div>
			</div>
			<div className='background-services'>
				<div className='container-social-network'>
					<div className='contain-social-network'>
						<h3>Catalogo en redes sociales</h3>
						<p>Creación de catalogo de tus productos, vende a traves de Facebook, Instagram o WhatsApp</p>
						<Link 
							className='btn-about' 
							to='https://wa.me/+543574511196?text=Hola,%20me%20interesa%20recibir%20más%20información%20acerca%20de%20la%20creación%20de%20catalogos%20en%20redes.' 
							style={{textDecoration: 'none'}} 
							onMouseMove={handleMouseMove}
	      					style={{ '--x': `${x}deg` }}>
							<i></i>
							<i></i>
							<span>Créalo</span>
						</Link>
					</div>
					<div className='container-social-image'>
						<img src={require(`../../images/catalogo.png`)} alt='catalogo' />
					</div>
				</div>
				<div className='container-technologies'>
					<h4 className='technologies-title'>Nuestras tecnologías</h4>
					<div className='contain-technologies'>
						<FaHtml5 className='technology-icon html' />
						<FaCss3Alt className='technology-icon css' />
						<IoLogoJavascript className='technology-icon javascript' />
						<FaReact className='technology-icon react' />
						<FaPython className='technology-icon python' />
						<BiLogoDjango className='technology-icon django' />
					</div>
				</div>
				<div className='container-branding'>
					<div className='contain-brand-img'>
						<img src={require(`../../images/brand-build.png`)} alt='brand-build' />
					</div>
					<div className='contain-brand-text'>
						<h3 className='branding-title'>Construye tu marca, destaca en la web</h3>
						<p className='branding-text'>En la era digital, el marketing se ha convertido en una herramienta indispensable para el éxito de cualquier negocio. 
						A través del marketing, las empresas pueden crear y mantener una conexión sólida con su audiencia, entender sus necesidades 
						y ofrecerles soluciones que resuenen con ellos. Una estrategia de marketing efectiva no solo aumenta la visibilidad de la 
						marca, sino que también construye credibilidad y confianza entre los consumidores.</p>
					</div>
				</div>
			</div>
			
			<div className='container-marketing-design'>
				<div className='contain-marketing-design'>
					<h3 className='marketing-design-title'>Posiciona tu marca</h3>
					<p>La primera impresión es muy importante. Además, un diseño atractivo y profesional puede diferenciar a una empresa de sus competidores, generar interés y confianza</p>
					<Link 
						className='btn-about' 
						to='/services/digital-marketing' 
						style={{textDecoration: 'none'}} 
						onMouseMove={handleMouseMove}
      					style={{ '--x': `${x}deg` }}>
						<i></i>
						<i></i>
						<span>Comienza</span>
					</Link>
				</div>
				<div className='marketing-design-image'>
					<img src={require(`../../images/posicionamiento.png`)} alt='positioning' />
				</div>
			</div>
			<div className='container-software'>
				<h3 className='software-title'>Software de ventas</h3>
				<p className='software-text'>Estamos creando nuestro software de ventas, para facilitar tus ventas, tu organización y aumentar la eficiencia de tu negocio.</p>
				<ThreeDots
					visible={true}
					height="80"
					width="80"
					color="#FBFAF8"
					radius="9"
					ariaLabel="three-dots-loading"
					wrapperStyle={{}}
					wrapperClass=""
				/>
			</div>
			<div className='container-faqs'>
				<h3 className='faqs-title'>Preguntas Frecuentes</h3>
				<FAQ />
			</div>
		</Layout>
	);
};

export default Services;