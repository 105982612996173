import Layout from '../../hocs/Layout';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { IoSearch } from "react-icons/io5";
import { Watch } from 'react-loader-spinner';
import { FaCircleChevronRight } from "react-icons/fa6";
import { HiBars3 } from "react-icons/hi2";
import '../../styles/ecommerce.css';


const EcommercePred = () => {

	useEffect(() => {
		window.scrollTo(0,0)
	}, []);




	return (

		<Layout>
			<div className='container-ecommerce-nav'>
				<div className='container-ecommerce-navbar'>
					<div className='contain-ecommerce-pcnav'>
						<div className='container-ecommerce-search'>
							<input />
							<IoSearch className='search-btn' />
						</div>
						<div className='container-ecommerce-navtitle'>
							<h3>Logo</h3>
						</div>
						<div className='container-ecommerce-navigation'>
							<p>Inicio</p>
							<p>Tienda</p>
							<p>Sobre nosotros</p>
							<p>Contacto</p>
							<p>Ingresar</p>
						</div>
					</div>
					<div className='contain-ecommerce-phonenav'>
						<div className='container-ecommerce-search'>
							<IoSearch className='search-btn' />
						</div>
						<div className='container-ecommerce-navtitle'>
							<h3>Logo</h3>
						</div>
						<div className='container-ecommerce-bars'>
							<HiBars3 className='search-btn' />
						</div>
					</div>
				</div>
				<div className='container-ecommerce-navleft'>
					<h3>Organiza tu barra de navegación</h3>
				</div>
			</div>
			<div className='container-ecommerce-colors'>
				<div className='container-ecommerce-colored'>
					<h3 className='colored-title'>Elige tus colores</h3>
				</div>
				<div className='container-ecommerce-color-img'>
					<img src={require(`../../images/paleta-colores.png`)} alt='paleta-colores' />
				</div>
			</div>
			<div className='container-ecommerce-images'>
				<div className='contain-ecommerce-images'>
					<h3>Selecciona tus imágenes</h3>
				</div>
				<div className='ecommerce-images-image'>
					<img src={require(`../../images/imagenes-producto.png`)} />
				</div>
				<div className='ecommerce-banner-title'>
					<h3>Y personaliza hasta dos banners en tu inicio</h3>
				</div>
			</div>
			<div className='container-ecommerce-payment'>
				<div className='ecommerce-payment-title'>
					<h3>Recibe tus pagos por Mercado pago o Paypal</h3>
				</div>
				<div className='container-payment-methods'>
					<div className='contain-ecommerce-payment'>
						<div className='payment-content'>
							<div className='payment-back'>
								<img src={require(`../../images/mercadopago-logo.png`)} />
							</div>
							<div className='payment-box'>
								<div className='payment-text'>
									<h4>Pagos por Mercado Pago</h4>
									<p className='text-with-chevron'><FaCircleChevronRight />Popularidad en América Latina</p>
									<p className='text-with-chevron'><FaCircleChevronRight />Variedad de métodos de pago</p>
									<p className='text-with-chevron'><FaCircleChevronRight />Financiamiento y cuotas</p>
									<p className='text-with-chevron'><FaCircleChevronRight />Seguridad</p>
									<p className='text-with-chevron'><FaCircleChevronRight />Costo competitivo</p>
								</div>
							</div>
						</div>
					</div>
					<div className='contain-ecommerce-payment'>
						<div className='payment-content'>
							<div className='payment-back'>
								<img src={require(`../../images/paypal-logo.png`)} />
							</div>
							<div className='payment-box'>
								<div className='payment-text'>
									<h4>Pagos por Paypal</h4>
									<p className='text-with-chevron'><FaCircleChevronRight />Reconocimiento global</p>
									<p className='text-with-chevron'><FaCircleChevronRight />Facilidad de uso</p>
									<p className='text-with-chevron'><FaCircleChevronRight />Protección al comprador y al vendedor</p>
									<p className='text-with-chevron'><FaCircleChevronRight />Compatibilidad con múltiples divisas</p>
									<p className='text-with-chevron'><FaCircleChevronRight />Seguridad robusta</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='container-ecommerce-products'>
				<div className='contain-ecommerce-products'>
					<h3>Puedes publicar hasta 200 productos diferentes</h3>
				</div>
				<div className='ecommerce-products-image'>
					<img src={require(`../../images/productos.png`)} />
				</div>
			</div>
			<div className='container-ecommerce-timer'>
				<h3>Deja de perder tu tiempo</h3>
				<div className='watch-timer'></div>
				<Watch
					visible={true}
					height="80"
					width="80"
					radius="48"
					color="#6DEC00"
					ariaLabel="watch-loading"
					wrapperStyle={{}}
					wrapperClass=""
				 />
				<Link to='https://wa.me/+543574511196?text=Hola,%20quiero%20obtener%20mi%20página%20web.' className='social-btn kching' style={{textDecoration:'none'}}>
					<span id='span-1'></span>
					<span id='span-2'></span>
					<span id='span-3'></span>
					<span id='span-4'></span>
					<p className='text-get-now'>Obtenla ahora</p>
				</Link>
			</div>
		</Layout>
	);
};

export default EcommercePred;