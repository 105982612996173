import Layout from '../../hocs/Layout';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import '../../styles/static-pages.css';
import { YoutubeVideo } from '../../components/aditional/YoutubeVideo';
import { ReactTyped } from 'react-typed';
import { PiCodeSimpleBold } from "react-icons/pi";
import { IoMdCreate } from "react-icons/io";
import { RiMegaphoneLine, RiPagesLine } from "react-icons/ri";
import { ContactForm } from '../../components/forms/ContactForm';
import { WhatsAppButton } from '../../components/aditional/WhatsApp';


const About = () => {

	useEffect(() => {
		window.scrollTo(0,0)
	}, []);

	return (

		<Layout>
			<div className='container-presentation-skills'>
				<div className='container-presentation'>
					<div className='contain-presentation'>
						<p className='aboutus-text'>Hola a todos!</p>
						<h2 className='presentation-title'>Soy <ReactTyped
		          					strings={['Leonel Brizio', 'Programador', 'Desarrollador', 'Diseñador']}
		          					typeSpeed={60}
		          					backSpeed={60}
		          					loop
		          					className='color-presentation-title'
		        		/></h2>
						<p className='aboutus-text'>Soy un joven argentino apasionado por el diseño web y la programación. 
						Este emprendimiento abarca desde pequeñas startups hasta grandes empresas, mi objetivo es brindar un servicio 
						que no solo te ayude a destacarte en el mercado, sino que también te haga más eficiente en todas tus operaciones.</p>
					</div>
					<div>
					</div>
				</div>
				<div className='container-skills'>
					<div className='boxes-skills'>
						<RiPagesLine className='skill-icons' />
						<h3>Diseño Web</h3>
						<p>Con experiencia en diseño UX/UI, me especializo en crear sitios web que no solo sean visualmente atractivos sino también 
						intuitivos y fáciles de navegar. Mi enfoque está en la experiencia del usuario.</p>
					</div>
					<div className='boxes-skills'>
						<IoMdCreate className='skill-icons' />
						<h3>Logotipo y Marca</h3>
						<p>Ofrezco servicios de diseño de logotipos y branding que capturan la esencia de tu negocio y resuenan con tu audiencia. 
						Creo identidades visuales que son memorables y representan fielmente tus valores y objetivos.</p>
					</div>
					<div className='boxes-skills'>
						<RiMegaphoneLine className='skill-icons' />
						<h3>Marketing</h3>
						<p>En el mundo digital actual, una estrategia de marketing efectiva es la diferencia entre el éxito y el fracaso. 
						Ofrezco soluciones de marketing que incluyen marketing en redes sociales, y campañas de publicidad.</p>
					</div>
					<div className='boxes-skills'>
						<PiCodeSimpleBold className='skill-icons' />
						<h3>Creación de Software</h3>
						<p>Desarrollo software personalizado que se adapta a las necesidades específicas de tu negocio. Ya sea que necesites 
						automatizar procesos, gestionar datos de manera eficiente, o mejorar la interacción con tus clientes.</p>
					</div>
				</div>
			</div>
			<div className='container-about-me'>
				<div class="parallax-container">
				    <div class="parallax">
				        <div class="parallax-contain">
				            <p class="parallax-text">Comencé creando soluciones a medida para emprendedores mediante el desarrollo de software personalizado utilizando VBA en Excel. Con el tiempo y tras realizar varios cursos y estudios, fui perfeccionando estos programas, lo que me permitió ofrecer soluciones aún más efectivas.</p>
				            <p class="parallax-text">Mi pasión por la programación me llevó más allá y me llevó a explorar el mundo del diseño web y el desarrollo de software para ventas y marketing. Mi objetivo es ayudar tanto a pequeñas como a grandes empresas, así como a emprendedores, a dar el salto al mundo digital. Quiero ayudarles a llegar a más clientes y obtener mejores resultados en todos sus proyectos.</p>
				        </div>
				    </div>
				</div>
			</div>
			<div className='container-about-software'>
				<div className='software-main'>
					<YoutubeVideo link={"https://www.youtube.com/embed/YU-8_dr6M0Q?si=Ik-0qrLTZ_YVFLmO"} />
				</div>
				<div className='contain-about-software'>
					<h3>Nuestros inicios</h3>
					<p>Comenzamos desarrollando software en plantillas de Excel, simples hojas de cálculo que se transforman en programas complejos. De 
					alli hasta la creación de sitios web cautivadores, software a medida y estrategias de marketing digital que impulsan a los 
					pequeños y grandes emprendedores con soluciones digitales.</p>
				</div>
			</div>
			<div className='container-mision'>
			</div>
			<div className='container-about-work'>
				<h3>Esta es nuestra manera de trabajar</h3>
				<div className='contain-about-work'>
					<div className='content-about-work'>
						<img className='about-work-img' src={require(`../../images/about-work1.png`)} alt='online-presence' />
						<h4 className='about-work-title'>Presencia en línea</h4>
						<div className='overlay'>
							<p className='about-work-text'>Imagina tener una presencia en línea que cautiva a tus clientes desde el primer clic, una plataforma web que simplifica tus procesos internos y te permite alcanzar tus metas comerciales de manera más rápida y efectiva. Esa es precisamente la experiencia que busco ofrecerte.</p>
						</div>
					</div>
					<div className='content-about-work'>
						<img className='about-work-img' src={require(`../../images/about-work2.png`)} alt='creative-focus' />
						<h4 className='about-work-title'>Enfoque creativo</h4>
						<div className='overlay'>
							<p className='about-work-text'>Con mi enfoque personalizado y creativo, me aseguro de entender tus necesidades específicas y trabajar contigo para desarrollar soluciones que no solo sean visualmente impactantes, sino también funcionales y altamente efectivas.</p>
						</div>
					</div>
					<div className='content-about-work'>
						<img className='about-work-img' src={require(`../../images/about-work3.png`)} alt='digital-tools' />
						<h4 className='about-work-title'>Herramientas digitales</h4>
						<div className='overlay'>
							<p className='about-work-text'>Desde la creación de tu sitio web hasta el desarrollo de aplicaciones personalizadas y estrategias de marketing digital, estoy aquí para ser tu aliado en el mundo digital. ¡Permíteme ayudarte a llevar tu negocio al siguiente nivel y destacarte en el competitivo mercado actual!</p>
						</div>
					</div>
				</div>
			</div>
			<div className='container-trusted-background'>
				<div className='container-trusted'>
					<h3 className='trusted-title'>Ya confiaron en nosotros</h3>
					<div className='contain-trusted'>
						<Link to='https://registrobienesmuebles.com.ar' className='container-links-trusted' target='_blank' style={{textDecoration: 'none'}}><img src={require(`../../images/logorbm.png`)} alt='logo-rbm' /></Link>
						<Link to='https://brendaledesma.blog' className='container-links-trusted' target='_blank' style={{textDecoration: 'none'}}><p className='brenda-ledesma'>Brenda Ledesma</p></Link>
					</div>
				</div>
			</div>
			<div className='container-contact-about'>
				<ContactForm />
			</div>
			<WhatsAppButton />
		</Layout>
	);
};

export default About;