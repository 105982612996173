import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import '../../styles/components.css'
import { Pagination, Navigation } from 'swiper/modules';



export const Slider = () => {
  	return (
    	<>
      		<Swiper
        		slidesPerView={1}
        		spaceBetween={30}
        		loop={true}
        		pagination={{
          			clickable: true,
        		}}
        		navigation={false}
        		modules={[Pagination, Navigation]}
        		className="mySwiper"
      			>
        			<SwiperSlide>
			        	<img src={require(`../../images/phone.png`)} alt='slide-image' />
			        </SwiperSlide>
			        <SwiperSlide>
			        	<img src={require(`../../images/phone2.png`)} alt='slide-image' />
			        </SwiperSlide>
			        <SwiperSlide>
			        	<img src={require(`../../images/phone3.png`)} alt='slide-image' />
			        </SwiperSlide>
			        <SwiperSlide>
			        	<img src={require(`../../images/phone4.png`)} alt='slide-image' />
			        </SwiperSlide>
      		</Swiper>
    	</>
  	);
}