import Layout from '../../hocs/Layout';
import { Link } from 'react-router-dom';
import { PackCard } from '../../components/cards/PackCard';
import { useEffect, useState } from 'react';
import { YoutubeVideo } from '../../components/aditional/YoutubeVideo';
import { ReactTyped } from 'react-typed';
import '../../styles/web-design.css';


const WebDesign = () => {

	useEffect(() => {
		window.scrollTo(0,0);
	}, []);

	const [x, setX] = useState(45);

  	const handleMouseMove = (e) => {
    	const rect = e.target.getBoundingClientRect();
    	const x = e.clientX - rect.left;
    	setX(x);
  	};

	const packsData = [
  		{	
  			id: 1,
    		title: "Plan Básico",
    		subtitle: "Subtítulo del pack 1",
    		price: 15,
    		paragraphs: [
      			{ titleParagraph: "Página Web Institucional", paragraph: "Diseño y desarrollo de un sitio web estático que presente información básica sobre la empresa o negocio." },
      			{ titleParagraph: "Blog Simple", paragraph: "Integración de un blog básico para publicar contenido relevante y mejorar la interacción con los visitantes del sitio." },
      			{ titleParagraph: "Landing Page", paragraph: "Creación de una landing page efectiva para promocionar productos, eventos o servicios específicos." },
      			{ titleParagraph: "Curriculum Vitae Online", paragraph: "Desarrollo de un currículum vitae digital para destacar las habilidades y experiencia del cliente." },
      			{ titleParagraph: "Portal Web", paragraph: "Creacion de un sitio web que contenga datos de servicio, ubicación, redes sociales entre otras." },
      			{ titleParagraph: "Diseño Responsive:", paragraph: "Adaptación del diseño para garantizar una experiencia óptima en dispositivos móviles." },
      			{ titleParagraph: "Soporte Técnico Básico", paragraph: "Asistencia técnica limitada para resolver problemas relacionados con la página web." }
    		]
  		},
  		{	
  			id: 2,
  			className: "recomended",
    		title: "Plan Estándar",
    		subtitle: "Subtítulo del pack 2",
    		price: 20,
    		paragraphs: [
      			{ titleParagraph: "Página Web Institucional Avanzada", paragraph: "Diseño y desarrollo de un sitio web más completo con contenido adicional y mayor funcionalidad." },
      			{ titleParagraph: "Funcionalidades Avanzadas", paragraph: "Incorporación de características como carrusel de imágenes, galería de fotos, integración de redes sociales, etc." },
      			{ titleParagraph: "Ecommerce Prediseñado", paragraph: "Integración de una tienda en línea básica con un diseño y flujo de usuario predefinidos. Incluye funcionalidades esenciales como catálogo de productos, carrito de compras y proceso de pago." },
      			{ titleParagraph: "Blog Integrado", paragraph: "Agregado de un blog para publicar contenido relevante y mejorar el SEO." },
      			{ titleParagraph: "SEO Básico", paragraph: "Optimización básica para motores de búsqueda." },
      			{ titleParagraph: "Diseño Responsive", paragraph: "Adaptación del diseño para garantizar una experiencia óptima en dispositivos móviles." },
      			{ titleParagraph: "Soporte Técnico Estándar", paragraph: "Asistencia técnica extendida para resolver problemas y realizar actualizaciones regulares." }
    		]
  		},
  		{	
  			id: 3,
    		title: "Plan Premium",
    		subtitle: "Subtítulo del pack 2",
    		price: 35,
    		paragraphs: [
      			{ titleParagraph: "Ecommerce Personalizado", paragraph: "Creación de una tienda en línea completa con capacidad para listar y vender productos." },
      			{ titleParagraph: "Diseño Premium y Personalizado", paragraph: "Desarrollo de un diseño altamente personalizado con elementos avanzados de diseño y animaciones." },
      			{ titleParagraph: "Optimización SEO Avanzada", paragraph: "Estrategias avanzadas de SEO para mejorar la visibilidad en los motores de búsqueda y aumentar el tráfico orgánico." },
      			{ titleParagraph: "Integración de Pago", paragraph: "Configuración de pasarelas de pago seguras para procesar transacciones en línea." },
      			{ titleParagraph: "Funcionalidades Avanzadas", paragraph: "Integración de características como sistema de gestión de clientes, chat en vivo, etc." },
      			{ titleParagraph: "Diseño Responsive", paragraph: "Adaptación del diseño para garantizar una experiencia óptima en dispositivos móviles." },
      			{ titleParagraph: "Soporte Técnico Prioritario", paragraph: "Asistencia técnica prioritaria con tiempos de respuesta más rápidos y mayor disponibilidad." }

    		]
  		}
	];

	return (

		<Layout>
			<div className='container-webd-header'>
				<div className='contain-webd-header'>
					<h3 className='webd-header-title'>
						Diseña tu <ReactTyped
          					strings={['Página Web', 'Blog Personal', 'Landing Page', 'E-commerce', 'Curriculum']}
          					typeSpeed={60}
          					backSpeed={60}
          					loop
        				/>
        			</h3>
					<Link 
						className='btn-discover' 
						to='https://wa.me/+543574511196?text=Hola,%20quiero%20obtener%20mi%20página%20web.'
						style={{textDecoration: 'none'}} 
						onMouseMove={handleMouseMove}
		      			style={{ '--x': `${x}deg` }}>
						<i></i>
						<i></i>
						<span>Créala ahora</span>
					</Link>
				</div>
				<div className='webd-header-image'>
					<img src={require(`../../images/diseño-web.png`)} alt='diseño-web' />
				</div>
			</div>
			<div className='container-web-design'>
				<div className='container'>
					<h3 className='packs-title'>Conoce nuestros planes</h3>
					<div className='container-packs'>
	      				{packsData.map((pack, index) => (
	        				<PackCard  key={index} {...pack} />
	      				))}
	      			</div>
	    		</div>
    		</div>
			<div className='container-ecommerce-pred'>
				<div>
					<YoutubeVideo link={"https://www.youtube.com/embed/YU-8_dr6M0Q?si=Ik-0qrLTZ_YVFLmO"} />
				</div>
				<div className='contain-ecommerce-pred'>
					<h3 className='ecommerce-pred-title'>Te ofrecemos nuestro e-commerce prediseñado</h3>
					<p className='ecommerce-pred-text'>Un sitio web de listo para comenzar tu negocio</p>
					<p className='ecommerce-pred-text'>Con algunos detalles personalizables</p>
					<Link 
						className='btn-discover' 
						to='/services/ecommerce' 
						style={{textDecoration: 'none'}} 
						onMouseMove={handleMouseMove}
	      				style={{ '--x': `${x}deg` }}>
						<i></i>
						<i></i>
						<span>Conoce más</span>
					</Link>
				</div>
			</div>
		</Layout>
	);
};

export default WebDesign